import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col, Button, Card, CardTitle, CardText, CardDeck,
  CardSubtitle, CardBody, Jumbotron, Modal, ModalHeader, ModalBody, Form, FormGroup, Input, Label
} from 'reactstrap';
import closearrow from '../../images/closeicon.svg';
import { FaArrowCircleLeft } from "react-icons/fa";

function Currentopenings() {
	const [modal, setModal] = useState(undefined);
	const [jobsInfo, setJobsInfo] = useState({});
	const [jobKey, setJobKey] = useState("");
	const [showJobDesc, setShowJobDesc] = useState(false);
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		resume: null,
	});
	const [isSubmitting, setIsSubmitting] = useState(false);

	const toggle = (name) => setModal(name);
	const closeBtn = (
		<button className="close" onClick={() => toggle(undefined)}>
			<img src={closearrow} alt="close" />
		</button>
	);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch("https://backend.decalogueinc.com/jobs/");
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				const jsonData = await response.json();
				setJobsInfo(jsonData);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, []);

	const getJobDescription = (key) => {
		if (jobsInfo[key]) {
			setJobKey(key);
			setShowJobDesc(true);
		}
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleFileChange = (e) => {
		setFormData({ ...formData, resume: e.target.files[0] });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsSubmitting(true);

		const data = new FormData();
		data.append("name", formData.name);
		data.append("email", formData.email);
		data.append("contact", formData.contact);
		data.append("job_code", jobsInfo[jobKey]?.job_code);
		data.append("job_id", jobsInfo[jobKey]?.id);
		data.append("resume", formData.resume);

		try {
			const response = await fetch("https://backend.decalogueinc.com/apply/", {
				method: "POST",
				body: data,
			});

			if (response.ok) {
				alert("Application submitted successfully!");
				setFormData({ name: "", email: "", resume: null });
				toggle(undefined);
			} else {
				alert("Failed to submit application.");
			}
		} catch (error) {
			console.error("Error submitting form:", error);
			alert("An error occurred.");
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<div>
			<Jumbotron fluid className="career-header">
				<Container>
					<h1 className="display-3 mt-50">Join Our Team</h1>
				</Container>
			</Jumbotron>
			<Container className="jobs">
				<Row>
					<Col md="12" sm="12">
						<div className={showJobDesc ? "hide" : "center-align"}>
							<CardDeck className="jobs-list">
								<Row>
									{Object.keys(jobsInfo).map((key) => {
										let job = jobsInfo[key];
										return (
											<Col md="6" key={key}>
												<Card>
													<CardBody>
														<CardTitle>{job.title}</CardTitle>
														<CardSubtitle>
															<strong>Date Posted :</strong> {new Date(job.created_at).toLocaleDateString()}{" "}
														</CardSubtitle>
														<CardSubtitle>
															<strong>Job Code :</strong> {job.job_code}{" "}
														</CardSubtitle>
														<CardSubtitle>
															<strong>Job Location :</strong> {job.job_location}
														</CardSubtitle>
														<CardText>
														{job.description.length > 300
															? job.description.slice(0, 300) + "..."
															: job.description}
														</CardText>
														<Button
															className="no-border btn btn-outline-primary btn-apply"
															onClick={() => {
																setJobKey(key);
																toggle("applynow");
															}}>
															Apply Now
														</Button>
														<Button
															color="link"
															className="readmore"
															onClick={() => getJobDescription(key)}>
															Read More
														</Button>
													</CardBody>
												</Card>
											</Col>
										);
									})}
								</Row>
							</CardDeck>
						</div>
					</Col>
				</Row>

				<Modal
					isOpen={modal === "applynow"}
					toggle={() => toggle(undefined)}
					style={{ maxWidth: "600px", margin: "10% auto" }}>
					<ModalHeader
						toggle={() => toggle(undefined)}
						close={closeBtn}
						style={{ borderBottom: "none" }}>
						<h5>Apply for {jobsInfo[jobKey]?.title}</h5>
					</ModalHeader>
					<ModalBody>
						<Form onSubmit={handleSubmit}>
							<FormGroup>
								<Label for="name">Name</Label>
								<Input
									type="text"
									name="name"
									id="name"
									value={formData.name}
									onChange={handleInputChange}
									required
								/>
							</FormGroup>
							<FormGroup>
								<Label for="contact">Contact Number</Label>
								<Input
									type="text"
									name="contact"
									id="contact"
									value={formData.contact}
									onChange={handleInputChange}
									required
								/>
							</FormGroup>
							<FormGroup>
								<Label for="email">Email</Label>
								<Input
									type="email"
									name="email"
									id="email"
									value={formData.email}
									onChange={handleInputChange}
									required
								/>
							</FormGroup>
							<FormGroup>
								<Label for="resume">Resume</Label>
								<Input
									type="file"
									name="resume"
									id="resume"
									onChange={handleFileChange}
									required
								/>
							</FormGroup>
							<Button
								type="submit"
								color="primary"
								disabled={isSubmitting}
								style={{ width: "100%" }}>
								{isSubmitting ? "Submitting..." : "Submit"}
							</Button>
						</Form>
					</ModalBody>
				</Modal>

				<div className={showJobDesc ? "" : "hide"}>
					<div className="job-details">
						<Row>
							<Col md="12" style={{ cursor: "pointer" }}>
								<span
									onClick={() => setShowJobDesc(false)}
									className="back-btn">
									<FaArrowCircleLeft className="bak-arrow" />{" "}
									<span className="back-btn-text">Back to see more jobs</span>
								</span>
							</Col>
						</Row>
						{jobsInfo[jobKey] && (
							<div>
								<CardTitle>{jobsInfo[jobKey].title}</CardTitle>
								<CardSubtitle>
									<strong>Date Posted:</strong>{" "}
									{new Date(jobsInfo[jobKey].created_at).toLocaleDateString()}
								</CardSubtitle>
								<CardSubtitle>
									<strong>Job ID:</strong> {jobsInfo[jobKey].job_code}
								</CardSubtitle>
								<CardSubtitle>
									<strong>Annual Pay:</strong> {jobsInfo[jobKey].annual_pay}
								</CardSubtitle>
								<CardSubtitle>
									<strong>Location:</strong> {jobsInfo[jobKey].job_location}
								</CardSubtitle>
								<CardText>{jobsInfo[jobKey].description}</CardText>
								<h2 className="job-desc">Primary Responsibilities:</h2>
								<ul className="check-list style2">
									{jobsInfo[jobKey].key_result_areas
										.split("\n")
										.map((desc, index) => (
											<li key={index}>{desc}</li>
										))}
								</ul>
								<h2 className="job-desc">Skills</h2>
								<ul className="check-list style2">
									<li>
										<strong>Primary Skills:</strong>{" "}
										{jobsInfo[jobKey].primary_skills}
									</li>
									<li>
										<strong>Secondary Skills:</strong>{" "}
										{jobsInfo[jobKey].secondary_skills}
									</li>
								</ul>
								<p>
									<strong>Note:</strong> Decalogue INC is an equal opportunity
									employer committed to upholding equal treatment to all staff.
									All qualified applicants will receive consideration without
									regard to their race, color, ethnicity, religion, gender,
									gender identity, marital status, sexual orientation, age,
									parental status, national origin, disability status, family or
									genetics background, or any other non-merit based factors.
									This commitment extends to all decisions and practices
									(including recruiting, hiring, promotions, and training) of
									the entire Decalogue INC management team.
								</p>
								<Button
									className="no-border btn btn-outline-primary btn-apply"
									onClick={() => toggle("applynow")}>
									Apply Now
								</Button>
							</div>
						)}
					</div>
				</div>
			</Container>
		</div>
	);
}

export default Currentopenings;
